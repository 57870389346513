import {
  Box,
  Divider,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Drawer,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  AvatarGroup,
  Avatar,
  TextField,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowBackSharp, CloseOutlined } from "@mui/icons-material";
import Draggable from "react-draggable";
import { MdOutlineHorizontalSplit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import group from "../../../assets/Icons/Group.svg";
import { TbDragDrop } from "react-icons/tb";
import { MdOutlinePivotTableChart } from "react-icons/md";
import { useDrop } from "react-dnd";

import BluePrint from "./BluePrint";
import Elements from "./Elements";
import { addMoreTables, deleteSingleTable, editTable } from "../../../axios/api";
import { useSnackbar } from "notistack";
import { IoCheckmark } from "react-icons/io5";

const LayoutDesign = ({ name, onEdit, tables, action, floorID }) => {
  const [id, setId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const nodes = [
    {
     
      Type: "tableRec",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableSquare",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableRec2",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableRec3",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableSquare2",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableCircle",
      Point: {
        x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize: 2,
      MinSize: 1,
    },
    {
    
      Type: "tableOval",
      Point: {
       x: 133,
        y: 50,
      },
      Name: "New",
      MaxSize:2,
      MinSize: 1,
    },
    // {
    
    //   Type: "bar",
    //   Point: {
    //     x: 133,
    //     y: 50,
    //   },
    //   Name: "New",
    //   MaxSize: 1,
    //   MinSize: 0,
    // },
  ];

  const [updatedTables, setUpdatedTables]= useState(tables)

  useEffect(()=>{
    setUpdatedTables(tables)
  }, [tables])

  const [nodePositions, setNodePositions] = useState(nodes);

  const [clickedNode, setClickedNode] = useState(null);


  // const addNode = (node) => {
  //   setClickedNode(node);
  //   const newNode = node;

  //   const updatedNodes = [...nodePositions, newNode];
  //   setNodePositions(updatedNodes);
  //   localStorage.setItem("table_position", JSON.stringify(updatedNodes));
  // };

  const handleDeleteById = async (id)=>{
    setIsLoading(true)
    await deleteSingleTable(id).then((res)=>{

      setIsLoading(false)
       if (res?.data?.status) {
  action()
   
       } else {
         handleAlert("error", `${res?.data?.error_message}`);
       }

    }).catch((err)=>{
      setIsLoading(false)

       handleAlert("error", `${err.message}`);
    })
  }

  const duplicateNode = (node) => {
    setMenuPosition((prev) => ({ ...prev, visible: false }));
    const duplicatedNode = {
      ...node,
      id: generateUniqueId(), // Ensure the new node has a unique ID
      position: {
        ...node.position,
        x: node.position.x + 20,
        y: node.position.y + 20,
      }, // Offset to avoid overlap
    };

    const updatedNodes = [...bluePrintElement, duplicatedNode];
    setBluePrintElement(updatedNodes);
    localStorage.setItem("bluePrint", JSON.stringify(updatedNodes));
  };

  // RIGHT CLICK

  const [menuPosition, setMenuPosition] = useState({
    x: 0,
    y: 0,
    visible: false,
  });
  const menuRef = useRef(null);

  const handleRightClick = (event, node) => {
    setClickedNode(node);
    event.preventDefault(); // Prevent the default context menu from appearing

    const { clientX: x, clientY: y } = event;
    setMenuPosition({
      x,
      y,
      visible: true,
    });
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuPosition((prev) => ({ ...prev, visible: false }));
    }
  };

  // Add an event listener to handle clicks outside the menu
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const [bluePrintElement, setBluePrintElement] = useState([]);

  useEffect(() => {
    let array = localStorage.getItem("bluePrint");

    if (array) {
      setBluePrintElement(JSON.parse(array));
    }
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "notes",
      drop: () => ({ name: "the bin" }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    []
  );

  const updateBluePrint = (node) => {
    setBluePrintElement(node);
  };
  const updateSelectedNode = (node) => {

    if (node) {
      setClickedNode(node);
    }
  };

  const [tableName, setTableName] = useState("");
  const [settings, setSettings] = useState("Formal");
  const [position, setPosition] = useState("Front Row");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  const [rows, setRows] = useState("");
  const [color, setColor] = useState("");
  const [shape, setShape] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [Type, setType] = useState("tableRec");
  const [point, setPoint] = useState(null);

  useEffect(() => {
    setTableName(clickedNode?.data?.Name);

    setSettings(clickedNode?.data?.Setting);
    setColor(clickedNode?.data?.Color);
    setShape(clickedNode?.data?.Shape || "square");
    setRows(clickedNode?.data?.Position);
    setMin(clickedNode?.data?.MinSize);
    setMax(clickedNode?.data?.MaxSize);
    setType(clickedNode?.data?.Type);
    setPoint(clickedNode?.position);
  }, [clickedNode]);

  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [isSuccessful, setIsSuccessful] = useState(false);
  const handleEditFloor = async () => {
    setIsLoading(true);
        setIsSuccessful(false)

    await editTable(
      clickedNode.data.Name,
      clickedNode.data.Position,
      clickedNode.data.Setting,
      clickedNode.data.Shape,
      color,
      clickedNode?.id,
     parseFloat(clickedNode.data.MinSize),
      parseFloat(clickedNode.data.MaxSize),
      clickedNode.data.Type,
      clickedNode.position
    )
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setIsSuccessful(true);
    
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", err.message);
      });
  };
  const handleEdit = async () => {
    setIsLoading(true);
        setIsSuccessful(false)

    await editTable(
      tableName,
      rows,
      settings,
      shape,
      color,
      clickedNode?.id,
      parseFloat(min),
      parseFloat(max),
      Type,
      point
    )
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setIsSuccessful(true);
          // handleAlert("success", "Table updated successfully");
          // setOpen(false);
          action();
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", err.message);
      });
  };

  

  useEffect(() => {
    setTimeout(() => {
      setIsSuccessful(false);
    }, 3000);
  }, [isSuccessful]);

    const handleAddMore = async (       name,
        maxSize,
        minSize,
        position,
        setting,
        shape,
        type,
        point) => {
    setIsLoading(true);
    setIsSuccessful(false)

    const payload = [
      {
        name,
        maxSize,
        minSize,
        position,
        setting,
        shape,
        type,
        point
      },
    ];


    await addMoreTables(floorID, payload)

      .then((res) => {
    
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
                    setIsSuccessful(true);
   action()
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", err.message);
      });
  };

  const updateTable = (table) =>{
    
    setUpdatedTables(table);
  }

  useEffect(()=>{
    if(clickedNode){

      handleEditFloor()


    }
  }, [clickedNode])



  return (
    <>
      <Box sx={{ p: 0 }}>
        <Box sx={{}}>
          <Box
            sx={{
              borderBottom: "0.5px solid #fff",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "background.card",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <IconButton onClick={() => onEdit()}>
                <ArrowBackSharp sx={{ }} />
              </IconButton>

              <Typography sx={{ fontWeight: 900 }}>
                {name}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
              {isLoading && (
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Autosaving Changes...{" "}
                  </Typography>
                  <CircularProgress size={15} />
                </Box>
              )}

              {isSuccessful && (
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Changes saved{" "}
                  </Typography>
                  <IoCheckmark style={{ color: "#4bb543" }} />
                </Box>
              )}
              <Button
                onClick={() => onEdit()}
                variant="outlined"
                sx={{
       
                  fontWeight: 400,
                  px: 3,
                  mr: 3,
                  fontSize: "12px",
                  borderRadius: "10px",
            
                }}
                startIcon={<TbDragDrop />}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 3, px: 4 }}>
            <Grid container spacing={2}>
              <Grid item lg={2.5}>
                <Box
                  sx={{
                    bgcolor: "#0e0d0d",
                    borderRadius: "4px",
                    border: "1px solid #242424",
                    boxSizing: "border-box",
                  }}
                >
                  <Box sx={{ p: 1, borderBottom: "0.5px solid #242424" }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: "12px",
                      }}
                    >
                      Elements
                    </Typography>
                  </Box>
                  <Box sx={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                    {nodePositions?.map((node, i) => (
                      <Elements
                        key={i}
                        node={node}
                        updateTable={updateTable}
                        updatedTables={updatedTables}
                        
                        handleAddMore={handleAddMore}
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={7} md={7}>
         
                  <BluePrint
                    bluePrintElement={updatedTables}
                    updateSelectedNode={updateSelectedNode}
                    handleRightClick={handleRightClick}
                    selectedElement={clickedNode}
                  />
              
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={12}>
                <Box
                  sx={{
                    bgcolor: "#0e0d0d",
                    borderRadius: "4px",
                    border: "1px solid #242424",
                    boxSizing: "border-box",
                  }}
                >
                  <Box sx={{ p: 1, borderBottom: "0.5px solid #242424" }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: "12px",
                      }}
                    >
                      Table Properties
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mx: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 1.5,
                      pb: 2,
                      borderBottom: "0.5px solid #242424",
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Table Name
                    </Typography>
                    <Box>
                      <TextField
                        value={tableName}
                        onChange={(e) => setTableName(e.target.value)}
                        sx={{ width: "50px" }}
                        size="small"
                        InputProps={{
                          style: {
                            fontSize: "10px",
                            borderRadius: "2px",
                            color: "#fff",
                            fontWeight: 500,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mt: 1.5, mx: 1 }}>
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Table Position
                    </Typography>
                    <TextField
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      size="small"
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          color: "#fff",
                          fontSize: "10px",
                        },
                      }}
                    >
                      <MenuItem
                        value={"Front Row"}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Front Row
                      </MenuItem>
                      <MenuItem
                        value={"Centre"}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Centre
                      </MenuItem>
                      <MenuItem
                        value={"Window Side"}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Window Side
                      </MenuItem>
                      <MenuItem
                        value="Corner"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Corner
                      </MenuItem>
                      <MenuItem
                        value={"Other"}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Other
                      </MenuItem>
                    </TextField>
                  </Box>
                  <Box sx={{ mt: 1.5, mx: 1 }}>
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Table Type
                    </Typography>
                    <TextField
                      value={Type}
                      onChange={(e) => setType(e.target.value)}
                      size="small"
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          color: "#fff",
                          fontSize: "10px",
                        },
                      }}
                    >
                {
                  nodes?.map((node,i)=>(
                          <MenuItem
                          key={i}
                        value={node?.Type}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                      {node?.Type}
                      </MenuItem>
                  ))
                }
                
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      mt: 1.5,
                      mx: 1,
                      pb: 1.5,
                      borderBottom: "0.5px solid #242424",
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Table Setting
                    </Typography>
                    <TextField
                      value={settings}
                      onChange={(e) => setSettings(e.target.value)}
                      size="small"
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          color: "#fff",
                          fontSize: "10px",
                        },
                      }}
                    >
                      <MenuItem
                        value="Formal"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Formal
                      </MenuItem>
                      <MenuItem
                        value="Casual"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Casual
                      </MenuItem>
                      <MenuItem
                        value="Breakfast"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Breakfast
                      </MenuItem>
                      <MenuItem
                        value="Buffet"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Buffet
                      </MenuItem>
                      <MenuItem
                        value="Five-Course"
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Five - Course
                      </MenuItem>
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      mx: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 1.5,
                      pb: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Min. Seat
                    </Typography>
                    <Box>
                      <TextField
                        value={min}
                        onChange={(e) => setMin(e.target.value)}
                        sx={{ width: "50px" }}
                        size="small"
                        InputProps={{
                          style: {
                            fontSize: "10px",
                            borderRadius: "2px",
                            color: "#fff",
                            fontWeight: 500,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mx: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 1.5,
                      pb: 2,
                      borderBottom: "0.5px solid #242424",
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", color: "#fff" }}>
                      Max. Seat
                    </Typography>
                    <Box>
                      <TextField
                        value={max}
                        onChange={(e) => setMax(e.target.value)}
                        sx={{ width: "50px" }}
                        size="small"
                        InputProps={{
                          style: {
                            fontSize: "10px",
                            borderRadius: "2px",
                            color: "#fff",
                            fontWeight: 500,
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ mt: 3, mx: 1 }}>
                    <Button
                      fullWidth
                      onClick={handleEdit}
                      disabled={!clickedNode}
                      variant="outlined"
                      sx={{ fontSize: "10px", py: 1, borderRadius: "4px" }}
                    >
                      Save
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        fontSize: "10px",
                        py: 1,
                        borderRadius: "4px",
                        my: 2,
                      }}
                      disabled={!clickedNode}
                      onClick={() => duplicateNode(clickedNode)}
                    >
                      Duplicate
                    </Button>
                    <Button
                      fullWidth
                      onClick={()=>handleDeleteById(clickedNode?.id)}
                      disabled={!clickedNode}
                      variant="contained"
                      sx={{
                        fontSize: "10px",
                        mb: 2,
                        py: 1,
                        borderRadius: "4px",
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {menuPosition.visible && (
        <Box
          ref={menuRef}
          className="context-menu"
          style={{
            position: "absolute",
            top: `${menuPosition.y}px`,
            left: `${menuPosition.x}px`,
            backgroundColor: "#242424",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            zIndex: 1000,
            opacity: menuPosition.visible ? 1 : 0,
            visibility: menuPosition.visible ? "visible" : "hidden",
            transition: "opacity 0.3s ease, visibility 0.3s ease",

            width: "200px",
          }}
        >
          <MenuItem sx={{ py: 0.2 }}>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", fontWeight: 400 }}
            >
              Rename
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => duplicateNode(clickedNode)}
            sx={{
              py: 0.2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "12px", fontWeight: 400 }}
            >
              Duplicate
            </Typography>
            <MdOutlineHorizontalSplit style={{ color: "#fff" }} />
          </MenuItem>

          <MenuItem
                onClick={()=>handleDeleteById(clickedNode?.Id)}
            sx={{
              py: 0.2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: "0.3px solid #ccccccb7",
              pt: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{ color: "#ed4337", fontSize: "12px", fontWeight: 400 }}
            >
              Remove
            </Typography>
            <RxCross2 style={{ color: "#ed4337" }} />
          </MenuItem>
        </Box>
      )}
    </>
  );
};

export default LayoutDesign;

