import { Avatar, Box, Typography } from '@mui/material'
import React from 'react';
import mobile from '../../assets/Icons/no-smartphone-turn-off-mobile-phone-icon-in-clipart-style-vector 2.jpg'



const NoMobile = () => {
  return (
<>

<Box sx={{height:'80vh', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', px:2}}>
<img src={mobile} width={100} alt="icon" />
<Typography sx={{mt:3, color:'#ffffff',  fontSize:'20px', textAlign:'center', fontWeight:700}}>Kindly use a laptop or tablet to access this feature</Typography>
</Box>
</>
  )
}

export default NoMobile