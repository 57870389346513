import React from "react";
import { useRoutes } from "react-router-dom";
import Waitlist from "./Waitlist";
import Login from "./Waitlist/Pages/Auth/Login";
import Register from "./Waitlist/Pages/Auth/Register";
import Pricing from "./Waitlist/Pages/Pricing";
import GetStarted from "./Waitlist/Pages/GetStarted";
import {
  BasicInformation,
  Experience,
  Gallery,
  Menu,
  Offerings,
  ReservationHours,
  RestaurantProfile,
  GuestBook,
  Tables,
  SharedLayout,
} from "./Dashboard/Pages/Dashboard";
import Dashboard from "./Dashboard/Pages/Dashboard/Dashboard";
import QuickSetup from "./Dashboard/Components/RestaurantProfile/QuickSetup";
import FloorPlan from "./Dashboard/Components/RestaurantProfile/FloorPlan";
import ProtectedRoute from "./Utility/ProtectedRoute";
import Reservations from "./Dashboard/Pages/Sections/Reservations";
import GuestBookMain from "./Dashboard/Pages/Sections/GuestBookMain";

import Room from "./Dashboard/Components/Tables/Room";
import ReservationManagement from "./Dashboard/Components/Reservations/ReservationManagement";
import CustomerSupport from "./Waitlist/Component/Support/CustomerSupport";
import Faq from "./Waitlist/Component/Support/Faq";
import Tutorial from "./Waitlist/Component/Support/Tutorial";
import SelectRestaurant from "./Dashboard/Pages/Sections/SelectRestaurant";
import { motion } from "framer-motion";
import Ticket from "./Dashboard/Components/Experience/Ticket";
import OnlineReservation from "./Dashboard/Pages/Dashboard/OnlineReservation";
import ResetPassword from "./Waitlist/Pages/Auth/ResetPassword";
import NotFound from "./Dashboard/Pages/NotFound";
import Billings from "./Dashboard/Pages/Sections/Billings";
import AdvanceReport from "./Dashboard/Components/AdvancedReport/AdvanceReport";
import RatingsReport from "./Dashboard/Components/AdvancedReport/RatingsReport/RatingsReport";
import AdvancedReservation from "./Dashboard/Components/AdvancedReport/Reservation/AdvancedReservation";
import Account from "./Dashboard/Pages/Dashboard/Account";
import CreatePassword from "./Dashboard/Components/Account/CreatePassword";

export function Routes() {
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const slideInVariants = {
    initial: { x: "-100vw" },
    animate: { x: 0 },
    exit: { x: "-100vw" },
  };
  const scaleVariants = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    exit: { scale: 0 },
  };

  const rotateVariants = {
    initial: { rotate: -180 },
    animate: { rotate: 0 },
    exit: { rotate: 180 },
  };

  const colorVariants = {
    initial: { color: "#ff0000" },
    animate: { color: "#00ff00" },
    exit: { color: "#0000ff" },
  };
  let element = useRoutes([
    {
      path: "/",
      element: <Waitlist />,
    },
     {
          path:"*",
          element:<NotFound/>
        },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/get-started",
      element: <GetStarted />,
    },
    {
      path: "/quick-setup",
      element: <QuickSetup />,
    },
    {
path:'/reset-password',
element:<ResetPassword/>
    },
    {
path:'/account/team/createpassword',
element:<CreatePassword/>
    },
    {
      path: "/customer-support",
      element: (
        <motion.div
          variants={fadeInOutVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <CustomerSupport />
        </motion.div>
      ),
    },
    {
      path: "/faq",
      element: <Faq />,
    },
            {
      path: "/guest-floorplan",
      element: <Reservations />,
    
    },
    {
      path: "/tutorials",
      element: <Tutorial />,
    },

    {
      path: "/dashboard/select",
      element: (
        <ProtectedRoute>
          <SelectRestaurant />
        </ProtectedRoute>
      ),
    },



    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <motion.div
            variants={slideInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <Dashboard />
          </motion.div>
        </ProtectedRoute>
      ),
    },
    {
      element: <SharedLayout />,
      children: [
        {
          path: "/dashboard/restaurantprofile",
          element: <RestaurantProfile />,
        },
            {
      path: "/dashboard/advanced-reports",
      element:     <motion.div
            variants={slideInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          ><AdvanceReport /></motion.div>,
    },
    {
path:'/dashboard/advanced-reports/rating-report',
element:<RatingsReport/>,
    },
    {
path:'/dashboard/advanced-reports/reservattion-report',
element:<AdvancedReservation/>,
    },
        {
          path: "/dashboard/basic-information",
          element: <BasicInformation />,
        },
        {
          path: "/dashboard/offerings",
          element: <Offerings />,
        },
        {
          path: "/dashboard/experience",
          element: <Experience />,
        },
        {
          path: "/dashboard/gallery-photos",
          element: <Gallery />,
        },
        {
          path: "/dashboard/guest-book",
          element: <GuestBook />,
        },
        {
          path: "/dashboard/online-reservation",
          element: <OnlineReservation />,
        },
        {
          path: "/dashboard/guest-book-main",
          element: <GuestBookMain />,
        },

        {
          path: "/dashboard/menu",
          element: <Menu />,
        },
        {
          path: "/dashboard/table-rooms",
          element: <Tables />,
        },
        {
          path: "/dashboard/room/:id",
          element: <Room />,
        },
        {
          path: "/reservation-management",
          element: <ReservationManagement />,
        },
        {
          path: "/floor-plan",
          element: <FloorPlan />,
        },
        {
          path: "/dashboard/reservation-hours",
          element: <ReservationHours />,
        },
        {
          path: "/dashboard/billings",
          element: <Billings />,
        },
        {
          path: "/dashboard/experience/tickets/:name/:id",
          element: <Ticket />,
        },
        {
          path: "/dashboard/account",
          element: <Account />,
        },
    

       
      ],
    },
  ]);
  return element;
}
