import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";


const COLORS = ["#FFC000", "#00FF47", "#06F", "#FF0025"];

const Reservation = ({Rdata}) => {

  const theme = useTheme()
  const isMobile  = useMediaQuery(theme.breakpoints.down("md"))


  const data = [
    { name: "Group A", value: Rdata?.TotalExpected },
    { name: "Group B", value: Rdata?.TotalSitted },
    { name: "Group C", value: Rdata?.TotalFinished },
    { name: "Group D", value: Rdata?.TotalCancelled },
  ];


  const total = Rdata?.TotalCancelled + Rdata?.TotalExpected + Rdata?.TotalFinished + Rdata?.TotalSitted


  return (
    <>
      {total === 0 ? (
        <>
          <Box
            sx={{
              width: {md:"200px ", sm:'200px', xs:'120px'},
              height:  {md:"200px ", sm:'200px', xs:'120px'},
              borderRadius: "50%",
              border: "20px solid #999999b7",
              mt: 5,
              ml: {md:10, sm:5, xs:0},
              display: "grid",
              placeItems: "center",
            }}
          >
            <Typography sx={{     fontSize: {lg:"14px", md:'14px', sm:'12px', xs:'12px'},  }}>No Reservation</Typography>
          </Box>
        </>
      ) : (
 <>
 {
  isMobile ? (
    <>
       <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx={80}
            cy={110}
            innerRadius={50}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
    </>
  ):(
    <>
       <PieChart width={500} height={400}>
          <Pie
            data={data}
            cx={170}
            cy={160}
            innerRadius={90}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
    </>
  )
 }

 </>
      )}
    </>
  );
}

export default Reservation
