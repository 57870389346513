import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Select,
MenuItem,
InputLabel
} from "@mui/material";
import { CgArrowLongLeft } from "react-icons/cg";
import { Link } from "react-router-dom";
import { TbFileTypeCsv } from "react-icons/tb";
import RatingBar from "./RatingBar";
import Calender from "../../Common/Calender";
import calendarIcon from "../../../../assets/Icons/calendar-tick.svg";
import { format, parseISO } from "date-fns";
import { TbMessage2 } from "react-icons/tb";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { CloseOutlined } from "@mui/icons-material";
import { RxPerson } from "react-icons/rx";
import { GoPeople } from "react-icons/go";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import { getRatingsReport } from "../../../../axios/api";
import Loader from "../../Common/Loader";
import { motion } from "framer-motion";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import emptyRes from '../../../../assets/emptyRes.svg'
import { MdNoiseAware } from "react-icons/md";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import CsvExport from "../../Common/CsvExport";


  const slideInVariants = {
    initial: { x: "100vw" },
    animate: { x: 0 },
    exit: { x: "100vw" },
  };
    const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#1a1a1a",
  borderRadius: "8px",
  boxShadow: 24,
};


const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <MdNoiseAware style={{fontSize:'12px'}}/>,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <MdNoiseAware style={{fontSize:'14px'}} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <MdNoiseAware style={{fontSize:'16px'}}/>,
    label: 'Neutral',
  },
  4: {
    icon: <MdNoiseAware style={{fontSize:'18px'}} />,
    label: 'Satisfied',
  },
  5: {
    icon: <MdNoiseAware style={{fontSize:'20px'}} />,
    label: 'Very Satisfied',
  },
};

const noise = {
  1: "Can't recall",
  2: "Low",
  3: "Moderate",
  4: "Noisy",
  5: "Too noisy",
};


function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value]?.icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const RatingsReport = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");
  const [isOverAll, setIsOverAll] = useState(true);
  const [singleReview, setSingleReview] = useState(null)

    const restaurantName = localStorage.getItem("restaurantName")



  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {};

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (data) => {
    setOpenModal(true);
setSingleReview(data);
  }
  const handleCloseModal = () => setOpenModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null)

  const handleGetRatingReview = async () =>{
setIsLoading(true);

await getRatingsReport()
.then((res)=>{
  setIsLoading(false)
  if(res?.data?.status) {
    setData(res?.data?.result)
  }

}).catch((err)=>{
    setIsLoading(false)

})
  }

  useEffect(()=>{
    handleGetRatingReview()
  },[])



   const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.GuestReviews?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.GuestReviews?.length}`;
  return (
    <>
    {
      isLoading && <Loader/>
    }
      <Box
        sx={{
          bgcolor: "#1a1a1a",
          border: "1px solid #fff",
          py: 1,
          px: 4,
          borderLeft: "0px",
        }}
      >
        {isOverAll ? (
          <Link to={`/dashboard/advanced-reports`}>
            <Button startIcon={<CgArrowLongLeft style={{ color: "#fff" }} />}>
              {" "}
              <Typography sx={{ color: "#fff", fontWeight: 500 }}>
                Ratings Reports
              </Typography>
            </Button>
          </Link>
        ) : (
          <Button
            startIcon={<MdOutlineKeyboardBackspace />}
            sx={{ color: "#fff" }}
            onClick={() => setIsOverAll(true)}
          >
            Back{" "}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          px: 4,
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "#fff", fontWeight: 500 }}>
          Overall rating
        </Typography>

<CsvExport data={data?.GuestReviews} name={`${restaurantName} Ratings - ${formatDate(new Date())}`}/>
      </Box>

      {isOverAll ? (
        <>
        
          <Box sx={{}}>
            <Box sx={{ mt: 3, px: 4 }}>
              <Box
                sx={{
                  mt: 3,
                  bgcolor: "#262626",
                  borderRadius: "12px",
                  p: 3,
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 5,
                }}
              >
                <Box align="center" sx={{ width: "15%" }}>
                  <Typography
                    sx={{ color: "#fff", fontSize: "40px", fontWeight: 700 }}
                  >
                  {data?.OverAll?.
Rating || 0}
                  </Typography>
                  <Typography
                    sx={{ color: "#b3b3b3", fontSize: "12px", mt: 0.5 }}
                  >
                    ({data?.OverAll?.TotalReviews || 0
} reviews)
                  </Typography>

                  <Divider sx={{ my: 2, bgcolor: "#989898" }} />
                  <Typography sx={{ color: "#fff", fontWeight: 500, fontSize:'12px' }}>
                    Highly rated for
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: "#FFEDED",
                      fontStyle: "italic",
                      mt: 1,
                      fontSize: "12px",
                      borderRadius: "50px",
                      fontWeight: 700,
                      height: "20px",
                      minWidth: "50px",
                      color: "#BC172F",
                    }}
                  >
                {data?.OverAll?.HighlyRatedFor || "--"}
                  </Box>
                </Box>
                <Box sx={{ width: "85%" }}>
                  <RatingBar rate={5} value={data?.OverAll?.FiveStarPercentage || 0
} />
                  <RatingBar rate={4} value={data?.OverAll?.FourStarPercentage || 0} />
                  <RatingBar rate={3} value={data?.OverAll?.ThreeStarPercentage || 0} />
                  <RatingBar rate={2} value={data?.OverAll?.TwoStarPercentage
 || 0} />
                  <RatingBar rate={1} value={data?.OverAll?.OneStarPercentage || 0} />
                </Box>
              </Box>
            </Box>

            <Box sx={{ px: 4, mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  Rating Details
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#1A1A1A",
                    cursor: "pointer",
                    px: 1.5,
                    py: 0.5,
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    border: "1px solid #fff",
                  }}
                  onClick={handleClick}
                >
                  <Box>
                    <img src={calendarIcon} alt="calendar" width="50%" />
                  </Box>
                  <Typography
                    sx={{ color: "#fff", display: "flex", fontSize: "12px" }}
                  >
                    {formattedDate1}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3}>
                    <Box
                      sx={{
                        height: "130px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Food Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                                    {data?.
RatingDetails?.Food || 0
}
                      </Typography>
                      {/* <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Box
                      sx={{
                        height: "130px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Service Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                                       {data?.
RatingDetails?.Service || 0
}
                      </Typography>
                      {/* <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Box
                      sx={{
                        height: "130px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Ambience Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                      {data?.
RatingDetails?.Ambience || 0
}
                      </Typography>
                      {/* <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Box
                      sx={{
                        height: "130px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Value Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                                        {data?.
RatingDetails?.Value || 0
}
                      </Typography>
                      {/* <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          {
          (  !data?.GuestReviews
|| data?.GuestReviews
?.length === 0 ) ? (
            <>
            <Box sx={{height:'400px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              <img src={emptyRes} alt="empty_icon"/>
              <Typography sx={{mt:2, color:'#fff', fontWeight:500, fontSize:'12px'}}>No Reviews </Typography>
            </Box>
            </>
          ):(
            <>
              <TableContainer sx={{ mt: 4 }}>
              <Table>
                  <TableHead sx={{ bgcolor: "#1a1a1a" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Overall Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Food Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Service Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Ambience Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Value Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Noise
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {

       data?.GuestReviews?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )?.map((item, index)=>(
                   <TableRow
                    key={index}
                    sx={{ bgcolor: "#333", cursor: "pointer" , "&:hover":{backgroundColor:'#262626'}}}
                    onClick={() => handleOpenModal(item)}
                  >
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                    {item?.
UserName || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                     {item?.
Overall?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {item?.
Food?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      {item?.
Service?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                                     {item?.
Ambience?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                                         {item?.
ValueForMoney?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                                          {item?.
NoiseLevels?.toFixed(2)
 || "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      <IconButton>
                        <TbMessage2
                          style={{ color: "#fff", fontSize: "16px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
       ))
                  }
    
                </TableBody>
                 
              </Table>
                  <Grid container sx={{  mt: 3, px: {md:4, sm:2, xs:0}, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#ccc",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#ccc",
                fontSize: "13px",
                fontFamily: "outfit",
      display:{md:'block', sm:'none', xs:'none'}
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#ccc",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#ccc" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#ccc" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#ccc" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#ccc" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #ccc",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #ccc",
                  "&.Mui-disabled": {
                    border: "1px solid #ccc1a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon sx={{color:'#ccc'}} />
              </IconButton>
              <IconButton
                disabled={endRow === data?.GuestReviews?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #ccc",
                  "&.Mui-disabled": {
                    border: "1px solid #ccc1a",
                  },
                }}
              >
                <ChevronRightRoundedIcon sx={{color:'#ccc'}} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
            </TableContainer>
            </>
          )
          }
          </Box>
        </>
      ) : (
        <>
            <motion.div
            variants={slideInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
          <Box>
            <Box sx={{ px: 4, mt: 3 }}>
              <Typography
                sx={{ color: "#fff", fontWeight: 500, fontSize: "20px" }}
              >
                Monday 5th July 2024
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item lg={3}>
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Food Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                        4.5
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography>
                      <Rating value={5} size="small" readOnly />
                    </Box>
                  </Grid>
                  <Grid item lg={3}>
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Service Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                        4.5
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography>
                      <Rating value={5} size="small" readOnly />
                    </Box>
                  </Grid>
                  <Grid item lg={3}>
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Ambience Rating
                      </Typography>

                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                        4.5
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography>
                      <Rating
                        value={4.5}
                        precision={0.5}
                        size="small"
                        readOnly
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={3}>
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#262626",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Value Rating
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "30px",
                          my: 1,
                        }}
                      >
                        4.5
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        1,459 reviews
                      </Typography>
                      <Rating
                        value={4.5}
                        precision={0.5}
                        size="small"
                        readOnly
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <TableContainer sx={{ mt: 4 }}>
              <Table>
                <TableHead sx={{ bgcolor: "#1a1a1a" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Overall Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Food Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Service Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Ambience Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Value Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    >
                      Noise
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: "12px",
                        border: "none",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow
                    hover
                    sx={{ bgcolor: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModal()}
                  >
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      George I.
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      4.5
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      3
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      1
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      <IconButton>
                        <TbMessage2
                          style={{ color: "#fff", fontSize: "16px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow hover sx={{ bgcolor: "#333" }}>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      12 Dec 2024
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      5.0
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      4.5
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      3
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      1
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px ",
                        fontFamily: "outfit",
                        color: "#fff",
                      }}
                    >
                      <IconButton>
                        <TbMessage2
                          style={{ color: "#fff", fontSize: "16px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </motion.div>
        </>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calender
          future={false}
          past={false}
          onChildValue={(value) => setValue(value)}
        />
        <Box sx={{ p: 2 }} align="right">
          <Button
            onClick={() => {
              handleClose();
              handleFilter();
            }}
            variant="contained"
            sx={{ px: 4 }}
          >
            Filter
          </Button>
        </Box>
      </Popover>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
          closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
         <Fade in={openModal}>
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "#333",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
              borderBottom: "1px solid #BC172F",
            }}
          >
            <Typography
              sx={{ fontSize: "12px", color: "#fff", fontWeight: 500 }}
            >
              Review Details
            </Typography>
            <IconButton onClick={() => handleCloseModal()}>
              <CloseOutlined sx={{ color: "#fff", fontSize: "15px" }} />
            </IconButton>
          </Box>

          <Box sx={{ width: "80%", margin: "0 auto", mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#fff", fontWeight: 500, fontSize:'12px' }}>
                Overall Rating
              </Typography>
              <Typography
                sx={{ fontWeight: 700, fontSize: "25px", my: 1, color: "#fff" }}
              >
              {singleReview?.
Overall?.toFixed(1) || 0}
              </Typography>
              <Rating value={singleReview?.
Overall?.toFixed(1) || 0}precision={0.1} size="small"  sx={{borderColor:'#fff'}}readOnly />
            </Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <RxPerson style={{ color: "#fff" }} />
                <InputLabel sx={{ color: "#fff", fontSize: "10px" ,textTransform:'capitalize'}}>
                  {singleReview?.UserName}
                </InputLabel>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <GoPeople style={{ color: "#fff" }} />
                <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  5 guest
                </Typography>
              </Box> */}
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <FaRegCalendarAlt style={{ color: "#fff" }} />
                <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  { singleReview ? format(new Date(singleReview?.ReviewDateTime), 'EEEE, MMMM yyyy') : "--"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <GoClock style={{ color: "#fff" }} />
                <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                { singleReview ? formatTime(singleReview?.ReviewDateTime) : "--"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#333",
              width: "90%",
              margin: "0 auto",
              mt: 3,
              borderRadius: "8px",
                 px:1,
            }}
          >
            <Box
              sx={{
                p: 2,
             
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing:'border-box'
              }}
            >
              <Typography sx={{ color: "#fff", fontSize:'12px', fontWeight:500 }}>Ambience</Typography>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
               <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  {singleReview?.Ambience?.toFixed(1) || 0}
                </Typography>
                <Rating value= {singleReview?.Ambience?.toFixed(1) || 0} readOnly  precision={0.1} size="small"/>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#fff",fontSize:'12px', fontWeight:500 }}>Food</Typography>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                  <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  {singleReview?.Food?.toFixed(1) || 0}
                </Typography>
                <Rating value= {singleReview?.Food?.toFixed(1) || 0} readOnly  precision={0.1} size="small"/>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#fff" ,fontSize:'12px', fontWeight:500}}>Service</Typography>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
           <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  {singleReview?.Service?.toFixed(1) || 0}
                </Typography>
                <Rating value= {singleReview?.Service?.toFixed(1) || 0} readOnly  precision={0.1} size="small"/>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#fff" , fontSize:'12px ', fontWeight:500}}>Noise</Typography>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                  {singleReview?.NoiseLevels?.toFixed(1) || 0}
                </Typography>
                {/* <Rating  readOnly  precision={0.1} size="small"/> */}
                     <StyledRating
                          size="small"
                        IconContainerComponent={IconContainer}
readOnly
                              sx={{
                            //  width: {sm:"60%", xs:'45%'},
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems:'center',
                            columnGap:1,
                            // color: "#BC172F",
                            boxSizing: "border-box",
                            ".MuiRating-label": {
                              display: "none",
                            },
                          }}
                       
                          name="serviceRating"
                       value= {singleReview?.NoiseLevels?.toFixed(1) || 0}
                    
                        />
                                   <InputLabel sx={{ fontSize: {sm:"8px", xs:'8px'}, }}>
                          {noise[singleReview?.NoiseLevels?.toFixed(0)]}
                        </InputLabel>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#fff" , fontSize:'12px ', fontWeight:500}}>Comment</Typography>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
              <Typography
                  sx={{ color: "#fff", fontSize: "12px", lineHeight: "18px" }}
                >
               {singleReview?.
Comment || "No comment"}
                </Typography>
              </Box>
            </Box>
        
          </Box>
          <Box align="right">
            <Button
              variant="contained"
              sx={{ my: 4, mr: 4, px: 5, borderRadius: "8px" }}
              onClick={() => handleCloseModal()}
            >
              Close
            </Button>
          </Box>
        </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RatingsReport;

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleTimeString([], options);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};