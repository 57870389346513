import {
  Box,
  Grid,
  Typography,
  Popover,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import tag from "../../../assets/Icons/table/filled/tag.svg";
import clock from "../../../assets/Icons/table/filled/clock.svg";
import flag from "../../../assets/Icons/table/filled/flag.svg";
import calendarIcon from "../../../assets/Icons/calendar-tick.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Reservation from "./Reservation";
import Calender from "../../Components/Common/Calender";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { format, parseISO } from "date-fns";
import Statistics from "./Statistics";
import emptyIcon from "../../../assets/emptyRes.svg";
import searchIcon from "../../../assets/Icons/search.svg";
import Analytics from "./Chart/Analytics";
import dataSet from "./Chart/data";
import ReGraph from "./Chart/ReGraph";
import DropdownSelector from "./Chart/DropdownSelector";
import { TiArrowRight } from "react-icons/ti";

const GeneralOverview = ({
  data,
  handleFilter,
  handleDetailsScreen,
  values,
  handleUpdateDate,
  tableData
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const [isLoading, setIsLoading] = useState(false);

  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");
  const date = new Date();
  const formatDate = () => {
    const options = { weekday: "long", month: "long", day: "numeric" };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    handleUpdateDate(value)
  }, [value])

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [data2, setData2] = useState(dataSet.Today);

  const fetchCustomData = (key) => {
    setData2(dataSet[key]);
  };


  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Box
              onClick={() => {
                if (values?.TodayOngoingReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayOngoingReservations);
                }
              }}
              sx={{
                bgcolor: "#19A662",
                p: 3,
                cursor:
                  values?.TodayOngoingReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontWeight: 500 }}
                >
                  Ongoing Reservations
                </Typography>
                {!values?.TodayOngoingReservations ||
                values?.TodayOngoingReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No ongoing reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayOngoingReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              onClick={() => {
                if (values?.TodayUpcomingReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayUpcomingReservations);
                }
              }}
              sx={{
                bgcolor: "#BC172F",
                p: 3,
                cursor:
                  values?.TodayUpcomingReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontWeight: 500 }}
                >
                  Upcoming Reservations
                </Typography>
                {!values?.TodayUpcomingReservations ||
                values?.TodayUpcomingReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No upcoming reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayUpcomingReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayUpcomingReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayUpcomingReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              onClick={() => {
                if (values?.TodayFinishedReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayFinishedReservations);
                }
              }}
              sx={{
                bgcolor: "#4787E6",
                p: 3,
                cursor:
                  values?.TodayFinishedReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontWeight: 500 }}
                >
                  Finished Reservations
                </Typography>
                {!values?.TodayFinishedReservations ||
                values?.TodayFinishedReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No finished reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayFinishedReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box sx={{ bgcolor: "background.paper", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px" }}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "40px",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Reservation Notes
                </Typography>
                <Button
                  endIcon={<TiArrowRight />}
                  sx={{ color: "#fff", fontSize: "12px" }}
                >
                  See More
                </Button>
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                  Today
                </Typography> */}

                <img src={emptyIcon} width={100} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ bgcolor: "background.paper", borderRadius: "10px" , boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px"}}>
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "40px",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Internal Notes
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={emptyIcon} width={100} />
                <Typography sx={{ fontSize: "10px", color: "#fff", mt: 2 }}>
                  There are no internal notes currently
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px" 
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{  }}>Reservations</Typography>
                  <Box
                    sx={{
                      bgcolor: "#1A1A1A",
                      cursor: "pointer",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      border: "1px solid #fff",
                    }}
                    onClick={handleClick}
                  >
                    <Box>
                      <img src={calendarIcon} alt="calendar" width="50%" />
                    </Box>
                    <Typography
                      sx={{ color: "#fff", display: "flex", fontSize: "12px" }}
                    >
                      {formattedDate1}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", columnGap: 5 }}>
                  <Box
                    sx={{
                      width: "50%",
                      height: "300px",
                    }}
                  >
                    <Reservation Rdata={data} />
                  </Box>
                  <Box sx={{ mt: 5, width: "40%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontWeight: 400 }}>
                        Status
                      </Typography>
                      <Typography sx={{ fontWeight: 400 }}>
                        Cover
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#00FF47" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Seated
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "11px", mt: 0.5 }}
                      >
                        {data?.TotalSitted}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#FFC000" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Expected
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "11px", mt: 0.5 }}
                      >
                        {data?.TotalExpected}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#06F" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Finished
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "11px", mt: 0.5 }}
                      >
                        {data?.TotalFinished}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Cancelled
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "11px", mt: 0.5 }}
                      >
                        {data?.TotalCancelled}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ mt: 4, fontSize: "13px" }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{ mt: 4, fontSize: "13px" }}
                      >
                        {data?.TotalCancelled +
                          data?.TotalFinished +
                          data?.TotalExpected +
                          data?.TotalSitted}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{}}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                      boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px" 
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ }}>Statistics</Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "grid",
                    placeItems: "center",
                    // border:'1px solid red'
                  }}
                >
                  <Statistics data={data} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: 3,
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
                    <Typography sx={{  fontSize: "10px" }}>
                      {data?.TotalOnline} Booked Online
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#fff" }} />
                    <Typography sx={{  fontSize: "10px" }}>
                      {data?.TotalWalkIn} Walk-ins
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                p: 3,
                boxSizing: "border-box",
                      boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px" 
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px" }}
                >
            Reservation Overview
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "300px" }}
                    placeholder="Search experiences"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <img
                            src={searchIcon}
                            style={{ marginRight: "8px" }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",
                     
                        fontSize: "12px",
                      },
                    }}
                  />
                  {/* <TextField select placeholder="Samm">
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                  </TextField> */}
                </Box>
              </Box>
              <Table sx={{ mt: 2 }}>
                <TableHead sx={{ bgcolor: "background.default", borderRadius: "5px" }}>
                  <TableRow>
                    <TableCell sx={{ }}>Time</TableCell>
                    <TableCell>Name of diner</TableCell>
                    <TableCell align="center">Party Size</TableCell>
                    <TableCell align="right">Order Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
       {
        tableData?.map((item, index)=>(
                     <TableRow key={index} sx={{ bgcolor: "#333" }} hover onClick={()=>handleDetailsScreen(item)}>
                    <TableCell sx={{ fontSize: "12px" }}>
                   {item?.Time}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                   {item?.DinnerName}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                   {item?.PartySize}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "12px" }}>
              {item?.TableName}
                    </TableCell>
                  </TableRow>
        ))
       }
         
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calender
          future={false}
          past={false}
          onChildValue={(value) => setValue(value)}
        />
        <Box sx={{ p: 2 }} align="right">
          <Button onClick={()=>{
          handleClose()
            handleFilter();
          
          }} variant="contained" sx={{ px: 4 }}>
            Filter
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default GeneralOverview;
