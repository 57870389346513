// Import necessary modules and utilities
import axios from "axios";
// import { LocalStorage } from "../utils";

const apiKey = process.env.REACT_APP_API_KEY;

const baseUrl = process.env.REACT_APP_BASE_URL;



let isRefreshing = false;
let failedQueue = [];

// Create an Axios instance for API requests
const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
});

// Add an interceptor to set authorization header with user token before requests
apiClient.interceptors.request.use(
  function (config) {
    // Retrieve user token from local storage
    const userToken = JSON.parse(window.localStorage.getItem("userInfo"));
    const selectedRestaurant = localStorage.getItem("restaurantID");

    // Set authorization header with bearer token
    config.headers.Authorization = `Bearer ${userToken?.access_token}`;
    config.headers.apiKey = apiKey;
    // config.headers["Access-Control-Allow-Origin"] = "*";

      if (selectedRestaurant) {
      // Make sure config.params exists
      config.params = config.params || {};
      config.params.restaurantID = selectedRestaurant;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
 
    if ( error.code === "ERR_NETWORK") {
    //  alert('unauthorized')
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);
// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (token) {
//       prom.resolve(token);
//     } else {
//       prom.reject(error);
//     }
//   });

//   failedQueue = [];
// };

// Add request interceptor to set authorization header with user token before requests
// apiClient.interceptors.request.use(
//   (config) => {
//     const userToken = JSON.parse(window.localStorage.getItem("userInfo"));
//     config.headers.Authorization = `Bearer ${userToken?.access_token}`;
//     config.headers.apiKey = apiKey;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Add response interceptor to handle token refresh logic
// apiClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.code === "ERR_NETWORK" && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           failedQueue.push({ resolve, reject });
//         })
//           .then((token) => {
//             originalRequest.headers.Authorization = `Bearer ${token}`;
//             return axios(originalRequest);
//           })
//           .catch((err) => {
//             return Promise.reject(err);
//           });
//       }

//       originalRequest._retry = true;
//       isRefreshing = true;

//       const refreshTokenKey = JSON.parse(window.localStorage.getItem("userInfo"))?.refresh_token;
      
//       if (!refreshTokenKey) {
//         // Handle case where there is no refresh token
//         alert("no key");
//         handleUnauthorized();
//         return Promise.reject(error);
//       }

//       try {
//         const { data } = await axios.post(`${baseUrl}/auth/refresh_jwt`, { refreshTokenKey });

//         // Store the new tokens
//         const newTokenInfo = {
//           ...JSON.parse(window.localStorage.getItem("userInfo")),
//           access_token: data.result.access_token
//         };
//         window.localStorage.setItem("userInfo", JSON.stringify(newTokenInfo));

//         apiClient.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
//         originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
//         processQueue(null, data.access_token);
//         isRefreshing = false;

//         return apiClient(originalRequest);
//       } catch (err) {
//         processQueue(err, null);
//         isRefreshing = false;
//         handleUnauthorized();
//         return Promise.reject(err);
//       }
//     }

//     return Promise.reject(error);
//   }
// );

function handleUnauthorized() {
  // Clear any authentication tokens if necessary
  // localStorage.removeItem("userInfo");

  // Redirect to the login page
  // window.location.href = '/login';
}

// API functions for different actions

const createBasicInfo = (
  restaurantName,
  adminFullName,
  emailAddress,
  restaurantWebsite,
  phoneNumber,
  country,
  lga,
  state,
  address,
  averageCost,
  logo,
  description,
  latitude,
  longitude
) => {
  const formData = new FormData();
  formData.append("RestaurantName", restaurantName);
  formData.append("AdminFullName", adminFullName);
  formData.append("EmailAddress", emailAddress);
  formData.append("RestaurantWebsite", restaurantWebsite);
  formData.append("PhoneNumber", phoneNumber);
  formData.append("Country", country);
  formData.append("LGA", lga);
  formData.append("State", state);
  formData.append("Address", address);
  formData.append("AverageCost", averageCost);
  formData.append("Logo", logo); // Assuming logo is a File or Blob object
  formData.append("Description", description);
  formData.append("Latitude", latitude);
  formData.append("Longitude", longitude);

  return apiClient.post("/restaurantmanagement/upload_basic_info", formData);
};

const galleryUpload = (restaurantId, img1, img2, img3) => {
  const formData = new FormData();

  formData.append("Photos", img1);
  formData.append("Photos", img2);
  formData.append("Photos", img3);
  return apiClient.post(
    `/restaurantmanagement/upload_gallery_photos/${restaurantId}`,
    formData
  );
};
const appendGalleryUpload = (images) => {
  const formData = new FormData();

  images.map((image) => {
    formData.append("Photos", image);
  });
    const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.post(
    `/restaurantmanagement/append_new_gallery_photos/${selectedRestaurant}`,
    formData
  );
};

const editGalleryUpload = (
  imageID,

  img1
) => {
  const formData = new FormData();

  formData.append("Photo", img1);

  return apiClient.put(
    `/restaurantmanagement/edit_gallery_photo/${imageID}`,
    formData
  );
};

const createOfferings = (
  restaurantId,
  menuLink,
  restaurantType,
  executiveChef,
  dietaryOptions,
  beverages,
  smokingOptions,
  sanitizationAndMaintainances,
  physicalDistancings,
  protectiveEquipments,
  screenings,
  amenities,
  seatingOptions,
  diningOptions,
  otherCuisines
) => {
  const postData = {
    menuLink,
    restaurantType,
    executiveChef,
    dietaryOptions,
    beverages,
    smokingOptions,
    sanitizationAndMaintainances,
    physicalDistancings,
    protectiveEquipments,
    screenings,
    amenities,
    seatingOptions,
    diningOptions,
    otherCuisines,
  };

  return apiClient.post(
    `/restaurantmanagement/upload_offerings/${restaurantId}`,
    postData
  );
};

const createExperience = (
  restaurantId,
  cancellationPolicy,
  depositPerPerson,
  description
) => {
  const payLoad = {
    cancellationPolicy,
    depositPerPerson,
    description,
  };
  return apiClient.post(
    `/restaurantmanagement/upload_experience/${restaurantId}`,
    payLoad
  );
};
const getRestuarentProfile = () => {
  return apiClient.get(`/restaurantmanagement/owner/all_restaurants`);
};

const getBasicInfo = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `/restaurantmanagement/get_restaurant/${selectedRestaurant}`
  );
};

const getOfferings = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `/restaurantmanagement/get_offerings/${selectedRestaurant}`
  );
};
const getGallery = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `/restaurantmanagement/get_all_photos/${selectedRestaurant}`
  );
};
const getMenuList = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `restaurantmanagement/get_restaurant_menu/${selectedRestaurant}`
  );
};

const createMenuList = (
  MenuItem,
  Description,
  MealPeriod,
  Price,
  Category,
  Img
) => {
  const formData = new FormData();
  formData.append("MenuItem", MenuItem);
  formData.append("Description", Description);
  formData.append("MealPeriod", MealPeriod);
  formData.append("Price", Price);
  formData.append("Category", Category);
  formData.append("Img", Img); // Assuming logo is a File or Blob object
    const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.post(
    `/restaurantmanagement/upload_menu/${selectedRestaurant}`,
    formData
  );
};

const createFloorPlans = (name, location, totalTables, restaurantId) => {
  const payload = {
    name,
    location,
    totalTables,
    restaurantId,
  };
  return apiClient.post(
    `/restaurantmanagement/floor_plan_with_tables/add`,
    payload
  );
};

const getFloorPlan = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `restaurantmanagement/floor_plan/all/${selectedRestaurant}`
  );
};

const getSingleFloorPlan = (id) => {
  return apiClient.get(`restaurantmanagement/floor_plan/get/${id}`);
};

const editTable = (
  name,
  position,
  setting,
  shape,
  color,
  id,
  minSize,
  maxSize,
  type,
  point
) => {
  const payload = {
  name,
  position,
  setting,
  shape,
  color,
  id,
  minSize,
  maxSize,
  type,
  point
  };
  return apiClient.put(`/restaurantmanagement/table/edit`, payload);
};

const createGuestBook = (
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  country,
  birthday,
  graduationAnniversary,
  weddingAnniversary
) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  const payload = {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    restaurantId:selectedRestaurant,
    country,
    birthday,
    graduationAnniversary,
    weddingAnniversary,
  };
  return apiClient.post(
    `restaurantmanagement/add_guestbook`,
    payload
  );
};

const getGuestBook = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `restaurantmanagement/get_all_guests/${selectedRestaurant}`
  );
};

const getReservations = (date) => {

  return apiClient.get(
    `reservation/owner/get_reservations?bookingDate=${date}`
  );
};
const createReservationHour = (dayOfWeeks, mealType, from, to) => {

      const selectedRestaurant = localStorage.getItem("restaurantID");
  const payload = {
    restaurantId: selectedRestaurant,
    dayOfWeeks,
    mealType,
    from,
    to,
  };
  return apiClient.post(`restaurantmanagement/add_reservation_hour`, payload);
};

const getReservationHours = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `restaurantmanagement/get_all_reservation_hour/${selectedRestaurant}`
  );
};
const getDashboardOverview = (date) => {
  return apiClient.get(
    `reservation/owner/get_day_reservation_stats?date=${date}`
  );
};

const getDashboardOverviewValue = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `reservation/owner/dashborard_overview?restaurantId=${selectedRestaurant}`
  );
};
const getDashboardOverviewAnalysis = (day) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `reservation/owner/dashborard_overview?restaurantId=${selectedRestaurant}&dayInterval=${day}`
  );
};

const getDashboadTables = (dayInterval) =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`reservation/owner/reservation_overview?restaurantId=${selectedRestaurant}&dayInterval=${dayInterval}`)
}
const deleteReservationHours = (id) => {
  return apiClient.delete(`restaurantmanagement/delete_reservation_hour/${id}`);
};

const editBasicInfo = (
  restaurantName,
  adminFullName,
  emailAddress,
  restaurantWebsite,
  phoneNumber,
  lga,
    state,
  address,
  latitude,
  longitude,
  averageCost
) => {
  const payload = {
    restaurantName,
    adminFullName,
    emailAddress,
    restaurantWebsite,
    phoneNumber,
    lga,
        state,
    address,
    latitude,
    longitude,
    averageCost,
  };
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.put(
    `restaurantmanagement/edit/basic_info/${selectedRestaurant}`,
    payload
  );
};

const editBasicInfoDescription = (description) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  const payload = {
    description,
  };
  return apiClient.put(
    `restaurantmanagement/edit/description/${selectedRestaurant}`,
    payload
  );
};
const editBasicInfoLogo = (logo) => {
  const formData = new FormData();
      const selectedRestaurant = localStorage.getItem("restaurantID");
  formData.append("Logo", logo);

  return apiClient.put(
    `restaurantmanagement/edit/logo/${selectedRestaurant}`,
    formData
  );
};

const editOfferings = (
  id,
  menulink,
  executiveChef,
  dietaryOptions,
  beverages
) => {

  const payload = {
    menulink,
    executiveChef,
    dietaryOptions,
    beverages,
  };
  return apiClient.put(`restaurantmanagement/edit/offerings/${id}`, payload);
};
const editCancellationPolicy = (
  experienceId,
    cancellationPolicy,
depositPerPerson,
HouseRules,
isEdit
) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  const payload = {
    experienceId,
    restaurantId : isEdit ? null : selectedRestaurant,
     cancellationPolicy,
depositPerPerson,
HouseRules
  };
  return apiClient.put(`restaurantmanagement/edit/experience`, payload);
};

const deleteTables = (ids) => {
  return apiClient.delete(`restaurantmanagement/table/delete_many/${ ids }`);
};
const deleteSingleTable = (id) => {
  return apiClient.delete(`restaurantmanagement/table/delete/${id}`);
};

const editSmoking = () => {
  return apiClient.put(``);
};

const changeBookingStatus = (id, status) => {
  const payload = {
    bookingId: id,
    status: status,
  };
  return apiClient.put(`reservation/owner/change_booking_status`, payload);
};

const searchGuestBook = (searchParams) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(
    `restaurantmanagement/search_guests?text=${searchParams}&restaurantId=${selectedRestaurant}`
  );
};

const deleteGalleryPhotos = (id) => {
  return apiClient.delete(`restaurantmanagement/delete_gallery_photo/${id}`);
};

const createWalkin = (restaurantId, date, time, guestId, partySize) => {
  const payload = {
    restaurantId,
    date,
    time,
    guestId,
    partySize,
  };
  return apiClient.post(`reservation/restaurant/book_as_walkin`, payload);
};

const editDining = (
  offeringsId,
  restaurantType,
  parkingOption,
  dressCode,
  amenities,
  smokingOptions,
  otherCuisines
) => {
  const payload = {
    offeringsId,
    restaurantType,
      parkingOption,
  dressCode,
    amenities,
    smokingOptions,
    otherCuisines,
  };
      const selectedRestaurant = localStorage.getItem("restaurantID");

  return apiClient.put(
    `restaurantmanagement/edit/dining_experience/restaurant/${selectedRestaurant}/offering/${offeringsId}`,
    payload
  );
};
const editSafetyPrecautions = (
  offeringsId,
  sanitizationAndMaintainances,
  physicalDistancings,
  protectiveEquipments,
  screenings
) => {
  const payload = {
    offeringsId,
    sanitizationAndMaintainances,
    physicalDistancings,
    protectiveEquipments,
    screenings,
  };

  return apiClient.put(
    `restaurantmanagement/edit/safety_preacautions/${offeringsId}`,
    payload
  );
};

// Events

const createEvents = (
  EventName,
  Description,
  EventDate,
  EventTime,
  EventImage
) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  const payload = {
    RestaurantId: selectedRestaurant,
    EventName,
    Description,
    EventDate,
    EventTime,
    EventImage,
  };
  const formData = new FormData();
  formData.append("RestaurantId", selectedRestaurant);
  formData.append("EventName", EventName);
  formData.append("Description", Description);
  formData.append("EventDate", EventDate);
  formData.append("EventTime", EventTime);
  formData.append("EventImage", EventImage);



  return apiClient.post(`restaurantevent/create`, formData);
};

const getEvent = () => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`restaurantevent/all/${selectedRestaurant}`);
};

const deleteEvent = (EventId) => {
  return apiClient.delete(`restaurantevent/delete/${EventId}`);
};
const deleteTicket = (TicketId) => {
  return apiClient.delete(`restaurantevent/ticket/delete/${TicketId}`);
};

const createTicket = (
  eventId,
  ticketName,
  totalTicket,
  description,
  ticketPrice
) => {
  const payload = {
    eventId,
    ticketName,
    totalTicket,
    description,
    ticketPrice,
  };
  return apiClient.post("restaurantevent/ticket/create", payload);
};

const getAllTickets = (id) => {
  return apiClient.get(`restaurantevent/${id}`);
};

const deleteRestaurant = (id) => {
  return apiClient.delete(`restaurantmanagement/restaurant/${id}`);
};
const editTicket = (
  ticketName,
  totalTicket,
  description,
  ticketPrice,
  ticketId
) => {
  const payload = {
    ticketName,
    totalTicket,
    description,
    ticketPrice,
    ticketId,
  };
  return apiClient.put("/restaurantevent/ticket/edit", { payload });
};

const findUserByEmail = (email) => {
  const payload = {
    email,
  };
  return apiClient.get(`userprofile/get_user_details_by_email`, { payload });
};

const getOpeningHours = (id, Date, Size) => {
  const payload = {
    RestaurantId: id,
    Date,
    Size,
  };
  return apiClient.get(
    `/reservation/guest/restaurant_opening_hour?RestaurantId=${id}&Date=${Date}&Size=${Size}`
  );
};

const createNewReservation = (restaurantId, date, time, guestId, partySize) => {
  const payload = {
    restaurantId,
    date,
    time,
    guestId,
    partySize,
  };
  return apiClient.post(
    "/reservation/restaurant/book_new_reservation",
    payload
  );
};

const getReservationNotes = (date) => {
  // return apiClient.get(
  //   `/reservation/owner/dashborard_overview?restaurantId=${selectedRestaurant}&days=${date}`
  // );
};

const forgotPassword = (email, link) =>{
const payload = {
  email:email,
  resetPasswordPageLink:link
}
  return apiClient.post('/auth/forget_password', payload)
}

const resetPassword = (  email,
  token,
  newPassword,
  confirmNewPassword,) =>{
  const payload  = {
  email,
  token,
  newPassword,
  confirmNewPassword,
  isMobile: false
}
return apiClient.post("/auth/reset_password", payload)
}

const deleteGuest = (id) =>{
  return apiClient.delete(`restaurantmanagement/delete_guest/${id}`)
}

const getGuestAnalysis =   (id) => {
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`restaurantmanagement/restaurant/${selectedRestaurant}/${id}`)
}

const addSpecialOccasion = ( eventName, eventDate, eventTimeFrom, eventTimeTo) =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
const payload = {
 restaurantId: selectedRestaurant, eventName, eventDate, eventTimeFrom, eventTimeTo
}

return apiClient.post(`restaurantmanagement/owner/special_occasion/add`, payload)
}

const getAllSpecialOccasion = () =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`restaurantmanagement/owner/${selectedRestaurant}/special_occasion/all`)
}

const editReservationHour = (dayOfWeeks, mealType, from, to, id) =>{
  const payload ={
    dayOfWeeks, mealType, from, to, id
  }
  return apiClient.put(`restaurantmanagement/edit_reservation_hour`, payload)
}

const editOccasion = (id, eventName, eventDate, eventTimeFrom, eventTimeTo) =>{
  const payload = {
    id, eventName, eventDate, eventTimeFrom, eventTimeTo
  }
  return apiClient.put(`restaurantmanagement/owner/special_occasion/edit`, payload)
}

const deleteSpecialHour = (id) =>{
return apiClient.delete(`/restaurantmanagement/owner/special_occasion/delete/${id}`)
}

const editEvent = (EventId,IsActive, EventName, Description,  EventDate, EventTime,EventImage) =>{

      const formData = new FormData();
  formData.append("EventId", EventId);
  formData.append("IsActive", IsActive);
  formData.append("EventName", EventName);
  formData.append("Description", Description);
  formData.append("EventDate", EventDate);
  formData.append("EventTime", EventTime);
  formData.append("EventImage", EventImage);


  return apiClient.put(`/restaurantevent/edit`, formData)
}

const sendSubscription = ( transactionId) =>{
const payload = {
  subcriptionPlan: 1,
  transactionId: transactionId
}



return apiClient.post('/subscription/add', payload)
}


const refreshToken = (token) =>{
  const payload = {
      value: token
  }

  return apiClient.post('/auth/refresh_jwt', payload)
}

const sendEmail = (Template,Subject,Attachment, EmailAddresses_Name) =>{

      const formData = new FormData();
        formData.append("Template", Template);
        formData.append("Subject", Subject);
        formData.append("Attachment", Attachment);
   EmailAddresses_Name.forEach((emailName, index) => {
    formData.append(`EmailAddresses_Name[${index}]`, emailName);
  });



        return apiClient.post("/email/guestbook/send", formData)

}

const deleteFloorplan =  (id)  =>{
return apiClient.delete(`/restaurantmanagement/floor_plan/delete/${id}`)
}

const editFloorPlan = (floorId, name,location, totalTables,restaurantId) =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
const payload = {
  floorId, name,location, totalTables,restaurantId:selectedRestaurant
}

return apiClient.put(`/restaurantmanagement/floor_plan_with_tables/edit`, payload)
}

const addMoreTables  = (floorPlanId,tables) =>{
  const payload = {
floorPlanId,
tables
  }
  return apiClient.post(`/restaurantmanagement/tables/add`, payload)
}


// ADVANCED REPORT

const getAdvancedReportData = () =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`/advancedreport/headers/${selectedRestaurant}`)
}

const getRatingsReport = () =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`/advancedreport/ratings/${selectedRestaurant}`)
}


const getMembers = () =>{
  return apiClient.get('/restaurantprofile/get_teams')
}
 
const getPermissions = () => {
  return apiClient.get("/restaurantprofile/valid_permissions")
}

const addTeamMember = (restaurantName, firstName,lastName, email, role, permissions, ) =>{
  const payload = {
    restaurantName, firstName,lastName, email, role, permissions
  }
return apiClient.post('/restaurantprofile/add_team', payload)
}

const deleteTeamMember = (id) =>{
return apiClient.delete(`/restaurantprofile/delete_team/${id}`)
}

const changeReservationTable = (bookingId, tableId) =>{
const payload = {
  bookingId, tableId
}
return apiClient.put("/reservation/owner/change_booking_table", payload)
}

const getMemberPermision = ( id) =>{
  return apiClient.get(`/restaurantprofile/team_permissions/${id}`)
} 

const editMemberPermissions = (teamMemberId, permissions) =>{

  return apiClient.put('/restaurantprofile/edit_team', {teamMemberId, permissions})
}

const deleteMember = (id) =>{
return apiClient.delete(`/restaurantprofile/delete_team/${id}`)
}

const confirmReservation = (id) =>{
  return apiClient.put(`/reservation/owner/confirm_reservation/${id}`)
}

const changePassword = (currentPassword, newPassword, confirmPassword) =>{
  const payload = {
currentPassword,
newPassword,
confirmPassword
  }

  return apiClient.put('/auth/change_password', payload)
}

const getReservationReport = (dayInterval) =>{
      const selectedRestaurant = localStorage.getItem("restaurantID");
  return apiClient.get(`/advancedreport/reservations_analytics?restaurantId=${selectedRestaurant}&dayInterval=${dayInterval}`)
}

// Export all the API functions
export {
  getDashboardOverview,
  getDashboardOverviewValue,
  getDashboardOverviewAnalysis,
  getDashboadTables,
  createBasicInfo,
  createOfferings,
  createExperience,
  getRestuarentProfile,
  galleryUpload,
  getBasicInfo,
  getOfferings,
  getGallery,
  getMenuList,
  createMenuList,
  createFloorPlans,
  getFloorPlan,
  getSingleFloorPlan,
  editTable,
  createGuestBook,
  getGuestBook,
  getReservations,
  createReservationHour,
  getReservationHours,
  deleteReservationHours,
  editBasicInfo,
  editBasicInfoDescription,
  editBasicInfoLogo,
  editOfferings,
  deleteTables,
  deleteSingleTable,
  changeBookingStatus,
  editGalleryUpload,
  appendGalleryUpload,
  searchGuestBook,
  deleteGalleryPhotos,
  createWalkin,
  editDining,
  editSafetyPrecautions,
  createEvents,
  getEvent,
  deleteEvent,
  createTicket,
  getAllTickets,
  deleteTicket,
  deleteRestaurant,
  editTicket,
  findUserByEmail,
  getOpeningHours,
  createNewReservation,
  getReservationNotes,
  forgotPassword,
  resetPassword,
  deleteGuest,
  getGuestAnalysis,
  addSpecialOccasion,
  getAllSpecialOccasion,
  editReservationHour,
  editOccasion,
  deleteSpecialHour,
  editEvent,
  editCancellationPolicy,
  sendSubscription,
  refreshToken,
  sendEmail,
  deleteFloorplan,
  editFloorPlan,
  addMoreTables,
  getAdvancedReportData,
  getRatingsReport,
  getMembers,
  getPermissions,
  addTeamMember,
  deleteTeamMember,
  changeReservationTable,
  getMemberPermision,
  editMemberPermissions,
  deleteMember,
  confirmReservation,
  changePassword,getReservationReport
};
