import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { CgArrowLongLeft } from "react-icons/cg";
import { Link } from "react-router-dom";
import { TbFileTypeCsv } from "react-icons/tb";
import CustomInput from "../../CustomField/CustomInput";
import { RiSearch2Line } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
import { getDashboadTables, getReservationReport } from "../../../../axios/api";
import Loader from "../../Common/Loader";
import { FaSortDown } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import emptyImg from '../../../../assets/emptyRes.svg'

const AdvancedReservation = () => {
  const [dayInterval, setDayInterval] = useState(1);

  const [reservationData, setReservationData] = useState(null);
  const [reservationTable, setReservationTable] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetReservationReport = async () => {
    setIsLoading(true);
    await getReservationReport(dayInterval)
      .then((res) => {
        if (res?.data?.status) {
          setReservationData(res?.data?.result);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleGetReservationTable = async () => {
    setIsLoading(true);
    await getDashboadTables(dayInterval)
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          setReservationTable(res?.data?.result);
        }
            setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetReservationReport();
    handleGetReservationTable();
  }, [dayInterval]);

  console.log(reservationTable);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, reservationTable?.length);
  const rowsText = `${startRow}-${endRow} of ${reservationTable?.length}`;
  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          bgcolor: "#1a1a1a",
          border: "1px solid #dedede",
          py: 1,
          px: 4,
          borderLeft: "0px",
        }}
      >
        <Link to={`/dashboard/advanced-reports`}>
          <Button startIcon={<CgArrowLongLeft style={{ color: "#fff" }} />}>
            {" "}
            <Typography
              sx={{ color: "#fff", fontWeight: 500, fontFamily: "optima" }}
            >
              Reservation Reports
            </Typography>
          </Button>
        </Link>
      </Box>

      <Box
        sx={{
          px: 4,
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "#fff", fontWeight: 500 }}>
          Reseravtions
        </Typography>

        <Button variant="contained" startIcon={<TbFileTypeCsv />}>
          Export as CSV
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 4, mt: 3 }}>
        <Grid item md={4}>
          <Box
            sx={{
              height: "130px",
              bgcolor: "#1a1a1a",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            <Box>
              <InputLabel
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                Total reservations
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: "20px",
                    my: 1,
                    fontFamily: "optima",
                  }}
                >
                  {parseFloat(
                    reservationData?.TotalReservation
                  )?.toLocaleString() || 0}
                </Typography>
                {isLoading && (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                )}
              </Box>
            </Box>
            {reservationData?.PercentageReservation >= 0 && (
              <Typography
                sx={{
                  color: "#189B62",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortUp />
                {`
                          ${reservationData?.PercentageReservation?.toFixed(
                            2
                          )}%` || "--"}
              </Typography>
            )}
            {reservationData?.PercentageReservation < 0 && (
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortDown />
                {`
                          ${reservationData?.PercentageReservation?.toFixed(
                            2
                          )}%` || "--"}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            sx={{
              height: "130px",
              bgcolor: "#1a1a1a",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            <Box>
              <InputLabel
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                Total reservations deposit
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: "20px",
                    my: 1,
                    fontFamily: "optima",
                  }}
                >
                  &#x20A6;{" "}
                  {parseFloat(
                    reservationData?.TotalReservationDeposit
                  )?.toLocaleString() || "--"}
                </Typography>
                {isLoading && (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                )}
              </Box>
            </Box>
            {reservationData?.PercentageReservationDeposit >= 0 && (
              <Typography
                sx={{
                  color: "#189B62",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortUp />
                {`
                          ${reservationData?.PercentageReservationDeposit}%` ||
                  "--"}
              </Typography>
            )}
            {reservationData?.PercentageReservationDeposit < 0 && (
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortDown />
                {`
                          ${reservationData?.PercentageReservationDeposit}%` ||
                  "--"}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            sx={{
              height: "130px",
              bgcolor: "#1a1a1a",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                Total party size
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: "20px",
                    my: 1,
                    fontFamily: "optima",
                  }}
                >
                  {parseFloat(
                    reservationData?.TotalPartySize
                  )?.toLocaleString() || "--"}
                </Typography>
                {isLoading && (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                )}
              </Box>
            </Box>
            {reservationData?.PercentagePartySize >= 0 && (
              <Typography
                sx={{
                  color: "#189B62",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortUp />
                {`
                          ${reservationData?.PercentagePartySize}%` || "--"}
              </Typography>
            )}
            {reservationData?.PercentagePartySize < 0 && (
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 500,
                  fontSize: "10px",
                }}
              >
                <FaSortDown />
                {`
                          ${reservationData?.PercentagePartySize}%` || "--"}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 2,
          mx: 4,
          bgcolor: "#1a1a1a",
          p: 3,
          boxSizing: "border-box",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: "#fff", fontWeight: 600, fontSize: "12px" }}>
            Reservation Overview
          </Typography>
          <Box
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="Search Reservation"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearch2Line style={{ color: "#fff" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "#fff",
                  fontSize: "12px",
                  border: "1px solid #fff",
                  borderRadius: "8px",
                },
              }}
            />
            <TextField
              size="small"
              select
              sx={{ width: "20%" }}
              InputProps={{
          
                style: {
                  color: "#fff",
                  fontSize: "12px",
                  border: "1px solid #fff",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem sx={{ fontSize: "10px", bgcolor: "#333" }}>
                All reservations
              </MenuItem>
            </TextField>
            <TextField
              size="small"
              select
              sx={{ width: "20%" }}
              value={dayInterval}
              onChange={(e) => setDayInterval(e.target.value)}
              InputProps={{
           
                style: {
                  color: "#fff",
                  fontSize: "12px",
                  border: "1px solid #fff",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem value={1} sx={{ fontSize: "10px" }}>
                Today
              </MenuItem>
              <MenuItem value={7} sx={{ fontSize: "10px" }}>
                Last Week
              </MenuItem>
              <MenuItem value={30} sx={{ fontSize: "10px" }}>
                Last 30 Days
              </MenuItem>
              <MenuItem value={60} sx={{ fontSize: "10px" }}>
                Last 60 Days
              </MenuItem>
            </TextField>
          </Box>
        </Box>
      
            {!reservationTable || reservationTable?.length === 0 ? (
              <>
     
                <Box
                  sx={{
                    height: "30vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={emptyImg} width={100}/>
                  {
                    dayInterval > 1 ?  (
      <Typography sx={{mt:2}}>No reservation record in the past {dayInterval} days</Typography>
                    ):(
                            <Typography sx={{mt:2}}>No reservations recorded for today</Typography>
                    )
                  }
            
                </Box>
     
              </>
            ) : (
              <>
                <TableContainer sx={{ mt: 3 }}>
          <Table>
            <TableHead sx={{ bgcolor: "#262626" }}>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "outfit",
                    fontSize: "12px",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "outfit",
                    fontSize: "12px",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Name of diner
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "outfit",
                    fontSize: "12px",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Party Size
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "outfit",
                    fontSize: "12px",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Order Details
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    fontFamily: "outfit",
                    fontSize: "12px",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
                <TableBody>
                  {reservationTable
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          bgcolor: "#333",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#262626" },
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "12px ",
                            fontFamily: "outfit",
                            color: "#ccc",
                          }}
                        >
                          {item?.Time}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px ",
                            fontFamily: "outfit",
                            color: "#ccc",
                          }}
                        >
                          {item?.DinnerName || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px ",
                            fontFamily: "outfit",
                            color: "#ccc",
                          }}
                        >
                          {item?.PartySize || "--"} Guest(s)
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px ",
                            fontFamily: "outfit",
                            color: "#ccc",
                          }}
                        >
                          {item?.BookingType || "--"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "10px ",
                              fontFamily: "outfit",
                              color: "#fff",
                              width: "fit-content",
                              px: 2,
                              fontWeight: 500,
                              borderRadius: "50px",
                              textAlign: "center",
                              ...(item?.Status === "Expected" && {
                                bgcolor: "#DF8E14",
                              }),
                              ...(item?.Status === "Sitted" && {
                                bgcolor: "#19A662",
                              }),
                              ...(item?.Status === "Finished" && {
                                bgcolor: "#4787E6",
                              }),
                            }}
                          >
                            {item?.Status || "--"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                          </Table>
               </TableContainer>
              </>
            )}

          <Grid container sx={{ mt: 3, px: 4, py: 1 }}>
            <Grid
              item
              lg={4}
              md={4}
              xs={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: "#ccc",
                  fontSize: "13px",
                  fontFamily: "outfit",
                }}
              >
                {rowsText}
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} xs={8}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#ccc",
                    fontSize: "13px",
                    fontFamily: "outfit",
                  }}
                >
                  You are currently on Page
                </Typography>
                <Select
                  size="small"
                  sx={{
                    fontFamily: "outfit",
                    color: "#ccc",
                    fontSize: "13px",
                  }}
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  InputProps={{
                    style: {
                      fontFamily: "outfit",
                      fontSize: "13px",
                      // borderRadius: "8px",

                      // Replace with your desired font family
                    },
                  }}
                >
                  <MenuItem
                    sx={{ fontFamily: "outfit", color: "#ccc" }}
                    value={5}
                  >
                    5
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "outfit", color: "#ccc" }}
                    value={10}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "outfit", color: "#ccc" }}
                    value={25}
                  >
                    25
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "outfit", color: "#ccc" }}
                    value={50}
                  >
                    50
                  </MenuItem>
                </Select>
                <Box
                  sx={{
                    borderLeft: "1px solid #ccc",
                    pl: { md: 4, xs: 2 },
                    ml: { md: 3, xs: 2 },
                  }}
                >
                  <IconButton
                    disabled={startRow === 1}
                    onClick={handleChangePageLeft}
                    sx={{
                      width: "30px",
                      height: "30px",
                      border: "1px solid #ccc",
                      "&.Mui-disabled": {
                        border: "1px solid #4242421a",
                      },
                    }}
                  >
                    <ChevronLeftRoundedIcon />
                  </IconButton>
                  <IconButton
                    disabled={endRow === reservationTable?.length}
                    onClick={handleChangePageRight}
                    sx={{
                      ml: 4,
                      width: "30px",
                      height: "30px",
                      border: "1px solid #ccc",
                      "&.Mui-disabled": {
                        border: "1px solid #4242421a",
                      },
                    }}
                  >
                    <ChevronRightRoundedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
   
      </Box>
    </>
  );
};

export default AdvancedReservation;
