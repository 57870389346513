import { Box, Button, InputLabel, TextField, Typography, InputAdornment,IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import logo from '../../../assets/Logo/logo.svg'
import bg from '../../../assets/watermark.png'
import { useSnackbar } from "notistack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { resetPassword } from '../../../axios/api';
import Loader from '../Common/Loader';

const CreatePassword = () => {
   const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get('token');
    const emailParam = params.get('email');
    const restaurantNameParam = params.get('restaurantname');

    setToken(tokenParam || '');
    setEmail(emailParam || '');
    setRestaurantName(restaurantNameParam ? decodeURIComponent(restaurantNameParam) : '');
  }, []);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
    const [showPassword, setShowPassword] = React.useState(false);

    const navigate = useNavigate()

      const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

      const handleResetPassword = async () => {
    setIsSuccessful(false);
    setIsLoading(true);
    await resetPassword(email, token, password, confirmPassword)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          setIsSuccessful(true);
          navigate('/login')
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err.message}`);
      });
  };
  return (
    <>
    {
      isLoading && <Loader/>
    }
    <Box sx={{background:`url('${bg}')`, backgroundSize:'600px', height:'100vh', display:'grid', placeItems:'center', backgroundColor:'#ccc'}}>
    <Box sx={{width:'35%', margin:'auto', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' , bgcolor:'#fff', borderRadius:'12px', px:2, boxSizing:'border-box', py:5, boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'}}>
<img src={logo}/>
<Box sx={{mt:5}}>

  <Typography sx={{fontSize:'12px', color:'#2b2b2b', textAlign:'center', lineHeight:'14px', }}>You have been invited to join the <b>{restaurantName}</b> team on Reisty.Your account information and reservations are securely managed by Reisty.</Typography>

  <Box sx={{width:'80%', margin:'0 auto',mt:4,}}>
    <InputLabel sx={{color:'#2b2b2b', fontSize:'12px', fontWeight:500}}>Email</InputLabel>
    <TextField
    fullWidth
        value={email}
     
    margin='dense'
    InputProps={{
      style:{
        borderRadius:'8px',
        fontSize:'12px',
        border:'1px solid #2b2b2b',
        color:'#2b2b2b',
        backgroundColor:'#3333331a',
            fontWeight:500
        
      },
      readOnly:true
    }}
    />
    <InputLabel sx={{color:'#2b2b2b', fontSize:'12px', mt:2, fontWeight:500}}>New Password</InputLabel>
    <TextField
            value={password}
                    onChange={(e) => setPassword(e.target.value)}
                       placeholder="**********"
 
    fullWidth
    margin='dense'
           type={showPassword ? "text" : "password"}
    InputProps={{
      style:{
        borderRadius:'8px',
        fontSize:'12px',
            border:'1px solid #2b2b2b',
        color:'#2b2b2b',
            fontWeight:500
      },
         endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon
                                  sx={{ fontSize: "16px", color:'#333' }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ fontSize: "16px" , color:'#333'}}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
    }}
    />
    <InputLabel sx={{color:'#2b2b2b', fontSize:'12px', mt:2, fontWeight:500}}>Confirm New Password</InputLabel>
    <TextField
           value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="**********"
    fullWidth

    margin='dense'
           type={showPassword ? "text" : "password"}
    InputProps={{
      style:{
        borderRadius:'8px',
        fontSize:'12px',
            border:'1px solid #2b2b2b',
        color:'#2b2b2b',
        fontWeight:400
      },
         endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon
                                  sx={{ fontSize: "16px" , color:'#333'}}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  sx={{ fontSize: "16px", color:'#333' }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
    }}
    />
    <Box sx={{mt:3}} align="right">
      <Button disabled={password != confirmPassword} onClick={handleResetPassword} variant='contained' fullWidth sx={{py:1.5, px:5, borderRadius:'10px', mb:2, "&:disabled":{background:'#333', cursor:'not-allowed', opacity:0.3}}}>
        Join team
      </Button>
<hr/>
             <Typography sx={{ fontSize: "12px", textAlign: "center",  }}>
                Already have an account?
                <Link
                  to="/login"
                  style={{
                    color: "#BC172F",
                    fontWeight: 400,
                    marginLeft: "10px",
                  }}
                >
                  Login
                </Link>
                .
              </Typography>
    </Box>
  </Box>
</Box>
    </Box>
    </Box>
    </>
  )
}

export default CreatePassword