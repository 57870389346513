import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ timeString }) => {



  function convertTo24HourFormat(timeString) {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':');
    
    let formattedHours = parseInt(hours, 10);
    if (period === 'PM' && formattedHours !== 12) {
        formattedHours += 12;
    } else if (period === 'AM' && formattedHours === 12) {
        formattedHours = 0;
    }
    
    const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
    return formattedTime;
}
const [endpointTime, setEndPointTime] = useState("")
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

useEffect(()=>{
        const Time = convertTo24HourFormat(timeString);
        setEndPointTime(Time)
}, [timeString])



  useEffect(() => {

    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateTimeRemaining() {

    const now = new Date();
    const targetTime = new Date();
    const [hours, minutes] = endpointTime.split(":");
    targetTime.setHours(parseInt(hours, 10));
    targetTime.setMinutes(parseInt(minutes, 10));
    targetTime.setSeconds(0);

    let difference = targetTime.getTime() - now.getTime();
    if (difference < 0) {
      difference = 0;
    }

    const hoursRemaining = Math.floor(difference / (1000 * 60 * 60));
    difference -= hoursRemaining * (1000 * 60 * 60);
    const minutesRemaining = Math.floor(difference / (1000 * 60));
    difference -= minutesRemaining * (1000 * 60);
    const secondsRemaining = Math.floor(difference / 1000);

    return { hours: hoursRemaining, minutes: minutesRemaining, seconds: secondsRemaining };
  }

  return (
    <div>

      <div>
        <p>Hours: {timeRemaining.hours}</p>
        <p>Minutes: {timeRemaining.minutes}</p>
        <p>Seconds: {timeRemaining.seconds}</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
