import React from 'react';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { Button } from '@mui/material';
import { TbFileTypeCsv } from "react-icons/tb";

const CsvExport = ({data,name}) => {
  const handleExport = () => {
    const csv = unparse(data);

    const blob = new Blob([csv], {type: 'text/csv; charset=utf-8;'});

    saveAs(blob, `${name}.csv`);
  }
  return (
<>
<Button startIcon={<TbFileTypeCsv />} variant='contained' onClick={handleExport}>Export as CSV</Button>
</>
  )
}

export default CsvExport